import { isRef, ref } from 'vue'

import { type MaybeRef } from '@vueuse/core'

import { type Image } from '@/types/image'

import { type Product } from '@/types'

export interface UseProductDetailOptions {

}

export const useProductDetail = (productDetail: MaybeRef<Product>, options?: UseProductDetailOptions) => {
  const product = isRef(productDetail) ? productDetail : ref(productDetail)

  const productTitle = product.value.title

  const productHandle = product.value.handle

  const productImages = computed<Image[]>(() => {
    if (!product.value || !product.value.images) return []
    return product.value.images.edges.map(edge => edge.node) as Image[]
  })

  const productImage = computed<Image>(() => {
    if (productImages.value.length) {
      return productImages.value[0]
    }
    return {} as Image
  })

  const productImageSrc = computed<string>(() => {
    if (productImage.value && productImage.value.url) {
      return productImage.value.url
    }

    return ''
  })

  const productImageAlt = computed<string>(() => {
    if (productImage.value && productImage.value.url) {
      return productImage.value.url
    }
    
    return `Kind & Conscious | ${product.value.title}`
  })

  const productCareInstructions = computed<string | null>(() => {
    return product.value.careInstructions?.value || null
  })


  return {
    title: productTitle,
    handle: productHandle,
    imageSrc: productImageSrc,
    imageAlt: productImageAlt,
    careInstructions: productCareInstructions
  }
}